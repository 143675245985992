import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _561b9045 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _e192d910 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _66cdc408 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _2a8d0491 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _6d8752c8 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _505b8faf = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _03000c9f = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _08b344fc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0e442666 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _25ca386a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _7d3794b6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _39c012e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _45393cba = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _84ae23f4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _61d0b8aa = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _51998c09 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _272d07ea = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _27243125 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _51e22e92 = () => interopDefault(import('../modules/catalog/pages/search-results.vue' /* webpackChunkName: "" */))
const _6f6e636c = () => interopDefault(import('../modules/catalog/pages/products.vue' /* webpackChunkName: "" */))
const _1f8ad9a1 = () => interopDefault(import('../pages/ResetPasswordPage.vue' /* webpackChunkName: "" */))
const _5f932dbd = () => interopDefault(import('../pages/StoreLocator.vue' /* webpackChunkName: "" */))
const _e9679252 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "" */))
const _3b172127 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _59548031 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _561b9045,
    name: "checkout",
    children: [{
      path: "user-account",
      component: _e192d910,
      name: "user-account"
    }, {
      path: "shipping",
      component: _66cdc408,
      name: "shipping"
    }, {
      path: "billing",
      component: _2a8d0491,
      name: "billing"
    }, {
      path: "payment",
      component: _6d8752c8,
      name: "payment"
    }, {
      path: "thank-you",
      component: _505b8faf,
      name: "thank-you"
    }]
  }, {
    path: "/cart",
    component: _03000c9f,
    name: "cart"
  }, {
    path: "/customer",
    component: _08b344fc,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _0e442666,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _25ca386a,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _7d3794b6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39c012e4,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "my-newsletter",
      component: _45393cba,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-wishlist",
      component: _84ae23f4,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _61d0b8aa,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _51998c09,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "my-reviews",
      component: _272d07ea,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "/reset-password",
      component: _27243125,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }]
  }, {
    path: "/search-results",
    component: _51e22e92,
    name: "search-results"
  }, {
    path: "/new-products",
    component: _6f6e636c,
    name: "new-products"
  }, {
    path: "/big-deal-products",
    component: _6f6e636c,
    name: "big-deal-products"
  }, {
    path: "/change-password",
    component: _1f8ad9a1,
    name: "change-password"
  }, {
    path: "/store-locator",
    component: _5f932dbd,
    name: "store-locator"
  }, {
    path: "/:slug+",
    component: _e9679252,
    name: "page"
  }, {
    path: "/",
    component: _3b172127,
    name: "home"
  }, {
    path: "/Cms",
    component: _59548031,
    name: "Cms"
  }, {
    path: "/Home",
    component: _3b172127,
    name: "Home"
  }, {
    path: "/Page",
    component: _e9679252,
    name: "Page"
  }, {
    path: "/ResetPasswordPage",
    component: _1f8ad9a1,
    name: "ResetPasswordPage"
  }, {
    path: "/StoreLocator",
    component: _5f932dbd,
    name: "StoreLocator"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
